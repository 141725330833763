import React, { useRef } from 'react';
import Page from 'components/templates/Page';
import { H2, H3 } from 'components/atoms/Typography';
import Divider from 'components/atoms/Divider';
import ContentBlock from 'components/templates/ContentBlock';
import WalletList from 'components/molecules/WalletList';
import CreditForm from 'components/molecules/CreditForm';
import AccountBalance from 'components/molecules/AccountBalance';
import Button from 'components/atoms/Button';
import TwitterAccount from 'components/organisms/TwitterAccount';
import ManualLinking from 'components/organisms/ManualLinking';
import LinkMetaMask from 'components/molecules/LinkMetaMask';
import Loader from 'components/atoms/Loader';
import Title from 'components/atoms/Title';
import { useLogin } from 'providers/LoginContext';
import { useAccountInformation } from 'utils/hooks';
import { signOut } from 'utils/firebase';
import Dialog, { useDialog } from 'components/molecules/Dialog';

const Account: React.FunctionComponent = () => {
  const { user } = useLogin();

  // Account  is protected thus user is always defined
  const accountQuery = useAccountInformation(user!);

  const creditDialogRef = useRef<HTMLDivElement | null>(null);
  const creditDialogManager = useDialog(creditDialogRef);
  const openCreditDialog = () => {
    creditDialogManager && creditDialogManager.open();
    // Following is needed for mysterious Slider freeze
    accountQuery.refetch();
  };

  return (
    <Page title="Moonlight Accounts">
      <ContentBlock>
        <Title />
        <Divider width="150px" mt="30px" mb="20px" />
        <H2> You are logged in. </H2>
        {accountQuery.isSuccess ? (
          <>
            <AccountBalance
              accountInformation={accountQuery.data}
              openCreditDialog={openCreditDialog}
            />
            <Divider width="150px" mt="30px" mb="20px" />
            <TwitterAccount />
            <Divider width="150px" mt="30px" mb="20px" />
            <WalletList wallets={accountQuery.data.wallets} />
            <H3> Add more addresses: </H3>
            <LinkMetaMask wallets={accountQuery.data.wallets} />
            <ManualLinking wallets={accountQuery.data.wallets} />
          </>
        ) : (
          <Loader size={80} />
        )}
        <Dialog ref={creditDialogRef}>
          {accountQuery.isSuccess &&
            !accountQuery.data.is_moonlight_premium_holder &&
            !accountQuery.data.whitelist_status.is_whitelisted && (
              <CreditForm />
            )}
        </Dialog>
        <Divider width="150px" mt="30px" mb="20px" />
        <Button outlined onClick={signOut}>
          Log out
        </Button>
      </ContentBlock>
    </Page>
  );
};

export default Account;
