import React, { useEffect } from 'react';
import { darken } from 'polished';
import MDCPage from './Material/MDCPage';
import styled from 'styled-components';

const Page = styled(MDCPage)`
  background: ${(props) => props.theme.themeDarkPurple};
  background: -webkit-linear-gradient(
    to bottom,
    ${(props) => props.theme.themeDarkPurple},
    ${(props) => darken(0.1, props.theme.themeDarkPurple)}
  ) !important;
  background: linear-gradient(
    to bottom,
    ${(props) => props.theme.themeDarkPurple},
    ${(props) => darken(0.1, props.theme.themeDarkPurple)}
  ) !important;
  min-height: 100vh;
`;

type MetaDataProps = {
  title?: string;
};
const PageWithMetaData: React.FunctionComponent<MetaDataProps> = ({
  title,
  children,
}) => {
  useEffect(() => {
    document.title = title ? title : 'Moonlight';
    return () => {
      document.title = 'Moonlight';
    };
  }, [title]);
  return <Page>{children}</Page>;
};

export default PageWithMetaData;
