import React from 'react';
import styled from 'styled-components';

const Img = styled.img`
  height: inherit;
`;

const Moonlight: React.FunctionComponent = () => {
  return (
    <Img
      alt="moonlight"
      src="https://storage.googleapis.com/public-moonlight/logo.svg"
    />
  );
};

export default Moonlight;
