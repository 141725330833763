import React from 'react';
import styled from 'styled-components';
import Text from 'components/atoms/Typography/Text';

type H2Props = Omit<React.ComponentProps<typeof Text>, 'tag'>;
const H2: React.FunctionComponent<H2Props> = ({ children, ...attributes }) => {
  return (
    <Text tag="h2" {...attributes}>
      {children}
    </Text>
  );
};

const StyledH2 = styled(H2)`
  font-family: Roboto, sans-serif;
  font-weight: 300;
  @media (max-width: 480px) {
    font-size: 1.2em;
  }
`;
export default StyledH2;
