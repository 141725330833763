import React, { useRef } from 'react';
import { TextAction } from 'components/atoms/Typography';

import Dialog, { useDialog } from 'components/molecules/Dialog';

const GoBackLink: React.FunctionComponent = () => {
  const dialogRef = useRef<HTMLDivElement | null>(null);
  const dialogManager = useDialog(dialogRef);

  const textAction = () => {
    dialogManager && dialogManager.open();
  };
  return (
    <>
      <TextAction action={textAction}>Not using Metamask?</TextAction>
      <Dialog ref={dialogRef}>
        <p>
          If you are not using a supported Web3 wallet like Metamask, you can
          always <b>sign in with Twitter</b> then manually link any BSC wallet
          to your account.
        </p>
        <p>
          You will have to perform a transaction with the wallet you want to
          link, more info is available once logged in.
        </p>
      </Dialog>
    </>
  );
};

export default GoBackLink;
