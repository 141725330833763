import React, { useEffect } from 'react';
import Page from 'components/templates/Page';
import { useLocation } from 'react-router';
import { Redirect } from 'react-router-dom';
import { useLogin } from 'providers/LoginContext';

const Authenticator: React.FunctionComponent = () => {
  const { user, isLoading } = useLogin();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const callback = urlParams.get('callback');
  useEffect(() => {
    const getToken = async () => {
      if (!user || isLoading) {
        return;
      }
      const token = await user.getIdToken();
      const response = await fetch('/functions/mint-custom-token', {
        method: 'POST',
        body: JSON.stringify({
          idToken: token,
        }),
      });
      const { idToken: customToken } = await response.json();
      window.location.href = `${callback}?token=${customToken}`;
    };
    getToken();
  }, [user, isLoading, location.search, callback]);

  if (!callback) return <Redirect to="/" />;
  return <Page />;
};

export default Authenticator;
