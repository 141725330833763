import styled from 'styled-components';

export default styled.li`
  width: 300px;
  background-color: ${(props) => props.theme.themeBlendingSurface};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 100px;
  padding: 10px;
  margin-bottom: 5px;
  @media (max-width: 480px) {
    width: 260px;
    font-size: 0.9em;
  }
`;
