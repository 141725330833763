import React from 'react';
import styled from 'styled-components';
import Box from 'components/atoms/Box';
import Text from 'components/atoms/Typography/Text';
import Moonlight from 'components/icons/moonlight';

type H1Props = Omit<React.ComponentProps<typeof Text>, 'tag'>;

const Lines = styled.span`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Line1 = styled.span`
  font-size: 1.5em;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 4px;
  @media (max-width: 480px) {
    font-size: 1.2em;
    letter-spacing: 2px;
  }
`;

const Line2 = styled.span`
  font-weight: 200;
  text-transform: uppercase;
  letter-spacing: 8px;
  margin-bottom: 6px;
  opacity: 0.6;
  @media (max-width: 480px) {
    letter-spacing: 4px;
  }
`;

const H1: React.FunctionComponent<H1Props> = ({ className, ...attributes }) => {
  return (
    <Text tag="h1" className={className} {...attributes}>
      <Box mr={['20px', '30px']} height={['50px', '70px']}>
        <Moonlight />
      </Box>
      <Lines>
        <Line1>Moonlight</Line1>
        <Line2>Accounts</Line2>
      </Lines>
    </Text>
  );
};

const StyledH1 = styled(H1)`
  display: flex;
  align-items: center;
  padding: 0 10px;
`;

export default StyledH1;
