import styled from 'styled-components';
import Button, { Ripple } from 'components/atoms/Button';

const SignInButton = styled(Button)`
  fill: ${(props) => props.theme.themePastelPurple};
  transition: color 0.3s ease, border-color 0.3s ease, fill 0.3s ease;
  @media (max-width: 480px) {
    font-size: 0.8em;
  }
  ${Ripple} {
    transition: 'background-color 0.3s ease';
  }
`;

export default SignInButton;
