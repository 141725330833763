import React, { useRef } from 'react';
import { useLogin } from 'providers/LoginContext';
import Button from 'components/atoms/Button';
import ConfirmationDialog, {
  useConfirmationDialog,
} from 'components/molecules/Dialog/ConfirmationDialog';

const Unlink: React.FunctionComponent = () => {
  const dialogRef = useRef<HTMLDivElement | null>(null);
  const { user, updateUser } = useLogin();
  const onAccept = async () => {
    try {
      const newUser = await user!.unlink('twitter.com');
      updateUser(newUser);
    } catch (e) {
      console.log(e);
    }
  };
  const dialogManager = useConfirmationDialog(dialogRef, onAccept);

  return (
    <>
      <Button onClick={() => dialogManager?.open()} outlined>
        Unlink
      </Button>
      <ConfirmationDialog ref={dialogRef}>
        Are you sure you want to unlink your Twitter account from this Moonlight
        account ?
      </ConfirmationDialog>
    </>
  );
};

export default Unlink;
