import React, { useState } from 'react';
import styled from 'styled-components';
import { formatAddress } from 'utils/bsc';
import { creditFormatter } from 'utils/format';
import { AccountCredit } from 'utils/types';
import { WEEK_CREDIT_RATE } from 'utils/constants';
import Ul from 'components/atoms/Ul';
import Li from 'components/atoms/Li';

type CreditTextProps = {
  accountCredit: AccountCredit;
};

const StyledUl = styled(Ul)`
  margin-bottom: 12px;
`;

const StyledLi = styled(Li)`
  margin: auto;
  margin-bottom: 4px;
  background-color: ${(props) => props.theme.themeGreyedPurple};
`;

const ToogleText = styled.span`
  color: ${(props) => props.theme.themePastelPurple};
  font-style: italic;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const CreditText: React.FunctionComponent<CreditTextProps> = ({
  accountCredit: { total_credit, spent_credit, wallets },
}) => {
  const availableCredit = total_credit - spent_credit;
  const maxWeeks = Math.floor(availableCredit / WEEK_CREDIT_RATE);
  const [detailsDisplayed, setDetailsDisplayed] = useState(false);

  return (
    <>
      <p>
        Each billion token purchased gives you one credit. This credit can be
        used to get temporary access to Bubble Maps. The rate is the following:{' '}
        <b>50 credits for one week of premium.</b>
      </p>
      {wallets.length > 0 ? (
        <p>
          You currently have {creditFormatter(availableCredit)} available credit
          {availableCredit < WEEK_CREDIT_RATE ? (
            <>
              .<br />
              <a
                href="https://pancakeswap.finance/swap?outputCurrency=0xb1ced2e320e3f4c8e3511b1dc59203303493f382"
                target="_blank"
                rel="noreferrer"
              >
                Buy Moonlight tokens
              </a>{' '}
              to receive more credit.
            </>
          ) : (
            <>
              , which makes up to{' '}
              <b>
                {maxWeeks} week{maxWeeks <= 1 ? '' : 's'}
              </b>{' '}
              of premium.
            </>
          )}{' '}
          <ToogleText onClick={() => setDetailsDisplayed(!detailsDisplayed)}>
            {' '}
            {detailsDisplayed ? 'Hide' : 'Show'} details{' '}
          </ToogleText>
        </p>
      ) : (
        <p>
          Your account isn’t linked to any BSC wallet. Please link one below to
          use credit.
        </p>
      )}
      {detailsDisplayed ? (
        <StyledUl>
          {wallets.map((wallet) => (
            <StyledLi key={wallet.address}>
              <span>{formatAddress(wallet.address)}</span>
              <div>
                Total: {creditFormatter(wallet.total_credit)} - Used:{' '}
                {creditFormatter(wallet.spent_credit)}
              </div>
            </StyledLi>
          ))}
        </StyledUl>
      ) : null}
    </>
  );
};

export default CreditText;
