import React, { useRef } from 'react';
import { TextAction } from 'components/atoms/Typography';

import Dialog, { useDialog } from 'components/molecules/Dialog';
import LinkForm from './LinkForm';
import { Wallet } from 'utils/types';

type ManualLinkingProps = {
  wallets: Wallet[];
};

const ManualLinking: React.FunctionComponent<ManualLinkingProps> = ({
  wallets,
}) => {
  const dialogRef = useRef<HTMLDivElement | null>(null);
  const dialogManager = useDialog(dialogRef);

  const textAction = () => {
    dialogManager && dialogManager.open();
  };
  return (
    <>
      <TextAction action={textAction}>Not using Metamask?</TextAction>
      <Dialog ref={dialogRef}>
        <h3>Manual wallet linking</h3>
        <p>
          If you are not using a supported Web3 wallet like Metamask, you can
          manually link any BSC wallet to your account here.
        </p>
        <p>
          Please fill the following form with the wallet address you want to
          link to your account. The form will give you a random BSC wallet
          address, you have to make a transaction of 1 Moonlight from the wallet
          address you gave us, to this random address.
        </p>
        <p>This will confirm that you are the owner of the wallet.</p>
        <LinkForm wallets={wallets} />
      </Dialog>
    </>
  );
};

export default ManualLinking;
