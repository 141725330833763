import React, { useState } from 'react';
import SignInWithMetaMask from './components/SignInWithMetamask';

import SignInWithTwitter from 'components/molecules/SignInWithTwitter';
import Divider from 'components/atoms/Divider';
import Error from 'components/atoms/Error';
import ContentBlock from 'components/templates/ContentBlock';
import GoBackLink from './components/GoBackLink';
import AlternativesInfo from './components/AlternativesInfo';
import Page from 'components/templates/Page';
import { H2 } from 'components/atoms/Typography';
import Title from 'components/atoms/Title';

export const formContext = React.createContext(
  {} as {
    isSubmiting: boolean;
    errors: string[];
    submit: (callback: () => Promise<any>) => void;
  }
);
const LoginForm: React.FunctionComponent = () => {
  const [isSubmiting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  const urlParams = new URLSearchParams(window.location.search);
  const landing_param = urlParams.get('landing');

  const submit = async (callback: () => Promise<any>) => {
    setErrors([]);
    setIsSubmitting(true);
    try {
      await callback();
    } catch (e) {
      console.log(e);
      setErrors([e.message]);
      setIsSubmitting(false);
    }
    // We don't set the state on success as we wait for redirection
  };
  return (
    <formContext.Provider value={{ errors, isSubmiting, submit }}>
      <Page>
        <ContentBlock>
          <Title />
          <Divider width="150px" mt="30px" mb="20px" />
          <H2>
            {landing_param === 'lemonn'
              ? 'Lemonn is using Moonlight Accounts.'
              : 'One Account. All of Moonlight.'}
          </H2>
          <Error>
            {errors.map((error) => (
              <span>{error}</span>
            ))}
          </Error>
          <SignInWithTwitter />
          <SignInWithMetaMask />
          <AlternativesInfo />
          <GoBackLink />
        </ContentBlock>
      </Page>
    </formContext.Provider>
  );
};

export default LoginForm;
