import React, { useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as Copy } from 'components/icons/copy.svg';

type ControlAddressProps = {
  control_address: string;
};

const ControlAddressContainer = styled.b`
  color: ${(props) => props.theme.themePastelPurple};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledCopy = styled(Copy)`
  width: 20px;
  height: 20px;
  margin-left: 8px;
  cursor: pointer;
  fill: white;
  &:hover {
    fill: ${(props) => props.theme.themePink};
  }
`;

const ControlAddress: React.FunctionComponent<ControlAddressProps> = ({
  control_address,
}) => {
  const [copied, setCopied] = useState(false);
  return (
    <>
      <ControlAddressContainer>
        <span>{control_address}</span>
        <StyledCopy
          onClick={() => {
            navigator.clipboard.writeText(control_address);
            setCopied(true);
          }}
        />
      </ControlAddressContainer>
      {copied ? <i>Copied to clipboard</i> : null}
    </>
  );
};

export default ControlAddress;
