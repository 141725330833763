import React from 'react';
import styled from 'styled-components';

import Ul from 'components/atoms/Ul';
import Li from 'components/atoms/Li';
import Loader from 'components/atoms/Loader';
import { Wallet } from 'utils/types';
import { formatAddress } from 'utils/bsc';
import { amountFormatter } from 'utils/format';
import { ReactComponent as Lemonn } from 'components/icons/lemonn.svg';
import { ReactComponent as Moonlight } from 'components/icons/moonlight.svg';

type WalletListProps = {
  wallets: Wallet[];
};

const NoWallet = styled.span`
  font-style: italic;
  color: ${(props) => props.theme.themeGreyedPurple};
`;

const TokensAmounts = styled.span`
  display: flex;
  align-items: center;
`;

const List: React.FunctionComponent<WalletListProps> = ({ wallets }) => {
  if (wallets.length === 0) {
    return <NoWallet> You have no linked addresses yet.</NoWallet>;
  }
  return (
    <Ul>
      {wallets.map((wallet) => (
        <Li key={wallet.address}>
          <span>{formatAddress(wallet.address)}</span>
          {wallet.moonlight_balance != null && wallet.lemonn_balance != null ? (
            <TokensAmounts>
              <span>{amountFormatter(wallet.lemonn_balance)}</span>
              <Lemonn style={{ margin: '0 8px 0 6px', width: '16px' }} />
              <span>{amountFormatter(wallet.moonlight_balance)}</span>
              <Moonlight style={{ marginLeft: '6px', width: '16px' }} />
            </TokensAmounts>
          ) : (
            <Loader size={18} />
          )}
        </Li>
      ))}
    </Ul>
  );
};

export default List;
