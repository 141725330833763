import styled from 'styled-components';
import { variant, margin, MarginProps } from 'styled-system';
import { theme } from 'theme';

const variants = {
  muted: {
    color: theme.themeGreyedPurple,
  },
};
type LinkProps = {
  variant?: keyof typeof variants;
} & MarginProps;

const Link = styled.a<LinkProps>`
  ${margin}
  ${variant({
    variants: variants,
  })}
  font-size: 0.8em;
  &&& {
    color: ${(props) => props.theme.themeGreyedPurple};
  }
  &:hover {
    text-decoration: underline;
  }
`;

export default Link;
