import React, { useRef } from 'react';
import { H3 } from 'components/atoms/Typography';
import Dialog, { useDialog } from 'components/molecules/Dialog';
import { ReactComponent as Help } from 'components/icons/help.svg';

type HeaderProps = {
  title: string;
};
const Header: React.FunctionComponent<HeaderProps> = ({ title }) => {
  const dialogRef = useRef<HTMLDivElement | null>(null);
  const dialogManager = useDialog(dialogRef);
  const iconAction = () => {
    dialogManager?.open();
  };
  return (
    <>
      <H3 iconAction={iconAction} Icon={Help}>
        {title}
      </H3>
      <Dialog ref={dialogRef}>
        <p>
          Link your account with Twitter to log into our apps without Metamask.
        </p>

        <p>
          If you hold 250 billion Moonlight tokens or more, this will also allow
          you to access the Poocoin Promoted Alerts bot on Twitter.
        </p>
      </Dialog>
    </>
  );
};

export default Header;
